import { graphql, useStaticQuery } from 'gatsby';
import { Col, Row } from 'react-awesome-styled-grid';
import styled, { ThemeProvider } from 'styled-components';

import CaptionedTitle from '../components/CaptionedTitle';
import Markdown from '../components/Markdown';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import { SectionInnerRow } from '../components/Rows';
import { lightTheme } from '../styles/themes';

const TermsPage = () => {
  const { data } = useStaticQuery(query);
  const { seo, title, caption, content } = data.edges[0].node;

  return (
    <Page seoTitle={seo.title} seoDescription={seo.description}>
      <ThemeProvider theme={lightTheme}>
        <PageHeader>
          <Row>
            <Col>
              <CaptionedTitle as="h2" caption={caption}>
                {title}
              </CaptionedTitle>
            </Col>
          </Row>
          <SectionInnerRow justify="center">
            <Col lg={8}>
              <MarkdownStyled content={content} />
            </Col>
          </SectionInnerRow>
        </PageHeader>
      </ThemeProvider>
    </Page>
  );
};

const MarkdownStyled = styled(Markdown)`
  h2 {
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-regular);
    margin-bottom: var(--spacing-5);

    &:not(:first-child) {
      margin-top: var(--spacing-5);
    }
  }
`;

const query = graphql`
  query {
    data: allTermsPageYaml {
      edges {
        node {
          seo {
            title
            description
          }
          title
          caption
          content
        }
      }
    }
  }
`;

export default TermsPage;
